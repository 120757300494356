export class Urls {
  static baseHttpUrl: string = `https://${process.env.NEXT_PUBLIC_API_BASE_URL}:${process.env.NEXT_PUBLIC_API_PORT}`;
  // static baseHttpUrl: string = `http://${process.env.NEXT_PUBLIC_API_LOCAL_URL}:${process.env.NEXT_PUBLIC_API_LOCAL_PORT}`;

  // Admin endpoints
  static getSubscriptions: string = `${this.baseHttpUrl}/admin/GetSubscriptions`;
  // Account endpoints
  static signIn: string = `${this.baseHttpUrl}/account/SignIn`;
  static validateToken: string = `${this.baseHttpUrl}/account/ValidateToken`;
  static getProfile: string = `${this.baseHttpUrl}/account/GetProfile`;
  static updateProfile: string = `${this.baseHttpUrl}/account/UpdateProfile`;
  static signOut: string = `${this.baseHttpUrl}/account/SignOut`;
  static getAuthors: string = `${this.baseHttpUrl}/account/GetAuthors`;
  static beginTrial: string = `${this.baseHttpUrl}/account/BeginTrial`;
  static checkTrialStatus: string = `${this.baseHttpUrl}/account/CheckTrialStatus`;
  static getPricingTable = `/api/price_table`;
  static deleteAccount: string = `${this.baseHttpUrl}/account/DeleteAccount`;
  static resetPassword: string = `${this.baseHttpUrl}/account/ResetPassword`;

  // Notification Filter CRUD
  static getAllNotificationFilters: string = `${this.baseHttpUrl}/account/GetNotificationFilters`;
  static addNotificationFilter: string = `${this.baseHttpUrl}/account/PutNotificationFilter`;
  static updateNotificationFilter: string = `${this.baseHttpUrl}/account/UpdateNotificationFilter`;
  static deleteNotificationFilter: string = `${this.baseHttpUrl}/account/DeleteNotificationFilter`;

  // notification endpoints
  static getNotifications: string = `${this.baseHttpUrl}/account/GetUserNotifications`;
  static updateNotifications: string = `${this.baseHttpUrl}/account/UpdateNotificationStatus`;

  // feed
  static feedSearch: string = `${this.baseHttpUrl}/feed/Search`;

  // Content endpoints
  static getContentConfig: string = `${this.baseHttpUrl}/content/GetContentConfig`;
  static getFeedListing: string = `${this.baseHttpUrl}/content/GetFeedListing`;
  static getPost: string = `${this.baseHttpUrl}/content/GetPost`;
  static putPost: string = `${this.baseHttpUrl}/content/PutPost`;
  static getSummaryCollection: string = `${this.baseHttpUrl}/content/GetSummaryCollection`;
  static getSummary: string = `${this.baseHttpUrl}/content/GetSummary`;
  static getDocument: string = `${this.baseHttpUrl}/content/GetDocument`;
  static getNewsFeedCollection: string = `${this.baseHttpUrl}/content/GetNewsFeedCollection`;
  static getBadges: string = `${this.baseHttpUrl}/content/GetBadges`;
  static getCategories: string = `${this.baseHttpUrl}/content/GetCategories`;
  static getHashtags: string = `${this.baseHttpUrl}/content/GetHashtags`;
  static addSavedPost: string = `${this.baseHttpUrl}/content/AddSavedPost`;
  static removeSavedPost: string = `${this.baseHttpUrl}/content/RemoveSavedPost`;
  static getKnowledgeAreas: string = `${this.baseHttpUrl}/content/GetKnowledgeAreas`;
  static getSavedPosts: string = `${this.baseHttpUrl}/content/GetSavedPosts`;
  static getOrganizations: string = `${this.baseHttpUrl}/content/GetOrganizations`;
  static getOrganization: string = `${this.baseHttpUrl}/content/GetOrganization`;
  static putKnowledgeArea: string = `${this.baseHttpUrl}/content/PutKnowledgeArea`;
  static deleteKnowledgeArea: string = `${this.baseHttpUrl}/content/DeleteKnowledgeArea`;

  static getSummaries: string = `${this.baseHttpUrl}/library/GetSummaries`;
  static getCollections: string = `${this.baseHttpUrl}/library/GetCollections`;
  static getLibraryListing: string = `${this.baseHttpUrl}/library/GetLibraryListing`;

  // Workspace endpoints
  static getUserWorkspace: string = `${this.baseHttpUrl}/workspace/GetUserWorkspace`;
  static getWorkspaceInfo: string = `${this.baseHttpUrl}/workspace/GetWorkspaceInfo`;
  static createWorkspace: string = `${this.baseHttpUrl}/workspace/CreateWorkspace`;
  static updateWorkspace: string = `${this.baseHttpUrl}/workspace/UpdateWorkspace`;
  static addWorkspaceUser: string = `${this.baseHttpUrl}/workspace/AddWorkspaceUser`;
  static removeWorkspaceUser: string = `${this.baseHttpUrl}/workspace/RemoveWorkspaceUser`;
  static addWorkspaceDomain: string = `${this.baseHttpUrl}/workspace/AddWorkspaceDomain`;
  static removeWorkspaceDomain: string = `${this.baseHttpUrl}/workspace/RemoveWorkspaceDomain`;
  static getWorkspaceInvites: string = `${this.baseHttpUrl}/workspace/GetWorkspaceInvites`;
  static acceptWorkspaceInvite: string = `${this.baseHttpUrl}/workspace/AcceptWorkspaceInvite`;
  static rejectWorkspaceInvite: string = `${this.baseHttpUrl}/workspace/RejectWorkspaceInvite`;
  static leaveWorkspace: string = `${this.baseHttpUrl}/workspace/LeaveWorkspace`;

  // Payment endpoints
  static createCheckoutSession: string = `${this.baseHttpUrl}/payment/CreateCheckoutSession`;
  static retrieveCheckoutSession: string = `${this.baseHttpUrl}/payment/RetrieveCheckoutSession`;
  static fetchSubscriptionData: string = `${this.baseHttpUrl}/payment/FetchSubscriptionData`;
  static manageBilling: string = `${this.baseHttpUrl}/payment/ManageBilling`;
  static getBillingInfo: string = `${this.baseHttpUrl}/payment/BillingInfo`;
  static updateBillingInfo: string = `${this.baseHttpUrl}/payment/UpdateBillingInfo`;
  static getInvoices: string = `${this.baseHttpUrl}/payment/GetInvoices`;

  // common endpoints
  static getCountries: string = `${this.baseHttpUrl}/common/GetCountries`;
  static getCountry: string = `${this.baseHttpUrl}/common/GetCountry`;

  static websiteUrl: string = 'https://lexai.co';
  static imprint: string = '$websiteUrl/imprint?app=true';
  static privacy: string = '$websiteUrl/privacy?app=true';
  static termsOfUse: string = '$websiteUrl/termsofuse?app=true';
  static faqs: string = '$websiteUrl/about?app=true';

  // social
  static linkedIn: string = 'https://www.linkedin.com/company/lexai/';
  static twitter: string = 'https://twitter.com/LEXAI12';
  static instagram: string = 'https://www.instagram.com/lex_ai_legaltech/';

  // ML API
  static mlProxyRagQuery: string = `${this.baseHttpUrl}/mlproxy/rag-query`;

  //search
  static getSearchFeedListing: string = `${this.baseHttpUrl}/feed/Search`;

  // Chatbot
  static monthlyQuotaAPI: string = `${this.baseHttpUrl}/workspace/GetWorkspaceRemainingAIQuota`;
  static chatbotAPI: string = `${this.baseHttpUrl}/chats/send`;
  static chatbotHistoryAPI: string = `${this.baseHttpUrl}/threads/`;
  static saveChatbotMessageAPI: string = `${this.baseHttpUrl}/chats/save`;
  static saveUserFileAPI: string = `${this.baseHttpUrl}/chats/saveUserFile`;

  // upload file
  static fileUploadAPI: string = `${this.baseHttpUrl}/common/UploadFile`;
}
