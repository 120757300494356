import { SubscriptionTier } from '@enums/subscription-type.enum';
import { Notification, Profile, TrialStatus } from '@interfaces/account.interface';
import { BaseApi } from '@interfaces/base-api.interface';
import { SummaryHistory, UserDocumentHistory } from '@interfaces/common.interface';
import { AppState } from '@interfaces/context.interface';
import { Workspaces } from '@interfaces/workspace.interface';
import { HttpApi } from '@lib/http.api';
import { Routes } from '@lib/routes';
import { StripeApi } from '@lib/stripe.api';
import { isUserHasInterest } from '@lib/utils/utils';
import AppStateContext from '@store/AppContext';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast, ToastOptions } from 'react-toastify';
import { getCurrentSession } from '../lib/amplify-auth';

type AppStateProviderProps = {
  children: React.ReactNode;
};

const AppStateProvider: React.FC<AppStateProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profileInfo, setProfileInfo] = useState<Profile>({});
  const [workspaceInfo, setWorkspaceInfo] = useState<Workspaces>({});

  const [billingInfo, setBillingInfo] = useState({
    trialStatus: null,
    subscriptionStatus: null,
  });

  const intl = useIntl();
  const toastOptions: ToastOptions = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: 'light',
  };

  const [notificationInfo, setNotificationInfo] = useState<{
    isLoading: boolean;
    updateNotificationId: string[];
    notifications: Notification[];
    showToastMsg: boolean;
  }>({
    isLoading: false,
    notifications: [],
    updateNotificationId: [],
    showToastMsg: false,
  });

  const [summaryHistory, setSummaryHistory] = useState<SummaryHistory>({
    prevSummaryId: '',
    totalData: Number.parseInt(process.env.NEXT_PUBLIC_PAGINATION_SIZE),
    filters: {
      collections: [],
      authors: [],
      hashtags: [],
      categories: [],
      badges: [],
      start_date: '',
      end_date: '',
      search_term: '',
    },
  });

  const [userDocumentHistory, setUserDocumentHistory] = useState<UserDocumentHistory>({
    prevUserDocumentId: '',
    totalData: Number.parseInt(process.env.NEXT_PUBLIC_PAGINATION_SIZE),
    filters: {
      collections: [],
      authors: [],
      hashtags: [],
      categories: [],
      badges: [],
      start_date: '',
      end_date: '',
      search_term: '',
    },
  });
  // by default making it as undefined, so we show correct screen in billing page
  const [isTrialUser, setIsTrialUser] = useState<boolean | undefined>(undefined);
  const [isPayingUser, setIsPayingUser] = useState<boolean>(false);

  const [chatbotDisplay, setChatbotDisplay] = useState(false);
  const [chatbotDoc, setChatbotDoc] = useState({});

  const [monthlyQuota, setMonthlyQuota] = useState(0);

  const router = useRouter();
  const stripeApi: StripeApi = StripeApi.Instance;
  const api: BaseApi = HttpApi.Instance;

  const updateLoadingState = useCallback((loading) => {
    setIsLoading(loading);
  }, []);

  const updateProfileInfo = useCallback((info) => {
    setProfileInfo(info);
  }, []);

  const updateWorkspaceInfo = useCallback((info) => {
    setWorkspaceInfo(info);
  }, []);

  const updateChatbotDoc = useCallback((doc) => {
    setChatbotDoc(doc || {});
  }, []);

  const triggerChatbotDisplay = useCallback((val) => {
    if (!val) setChatbotDoc({});
    setChatbotDisplay(val);
  }, []);

  const fetchData = useCallback(async () => {
    // if the user is logging out, cancel data fetching
    if (router.asPath.includes(Routes.logout) || router.asPath.includes(Routes.pricing)) {
      return;
    }

    try {
      // Get current user session
      await getCurrentSession();

      // If user is successfully signed in, but is still going to login page, redirect to home page
      if (router.asPath.includes('login')) {
        router.replace(`/${router.locale}${Routes.home}`, `/${router.locale}${Routes.home}`, {
          shallow: true,
        });
        return;
      }

      // If user has an active session, proceed to fetch user data
      const profile = await api.getProfile();
      setProfileInfo(profile);

      const workspace = await api.getUserWorkspace();
      setWorkspaceInfo(workspace);

      // if user is already on onboarding page, redirecting to onboarding page will lead to an endless loop.
      if (router.asPath.includes(Routes.onboarding)) {
        return;
      }

      if (!isUserHasInterest(profile) || !workspace) {
        router.replace(`${Routes.onboarding}`, `${Routes.onboarding}`, {
          shallow: true,
        });
        return;
      }

      // If user data is valid, get payment information/status
      const [subscriptionStatus, trialStatus] = await Promise.all([
        stripeApi.getSubscriptions(workspace.workspace_id),
        api.checkTrialStatus(),
      ]);
      setBillingInfo((prevBillingInfo) => ({
        ...prevBillingInfo,
        subscriptionStatus,
        trialStatus,
      }));

      const isTrial = trialStatus !== null && trialStatus['status'] == TrialStatus.ACTIVE;

      // checking if we have tiers and tiers contain valid subscription
      const isValidSubscriptionTier =
        subscriptionStatus?.tiers?.length > 0 && subscriptionStatus.tiers[0] > SubscriptionTier.NONE;

      // if for some reason we don't get tiers then we fallback to check stripe subscription object to see if status is active of the current plan
      const stripeSubscriptionStatus = subscriptionStatus?.subscription?.status === 'active';

      // If both above are false then it might be enterprise user if not mean, user has no current paid plan
      const isPaying = isValidSubscriptionTier || stripeSubscriptionStatus || workspace?.isEnterprise;

      setIsTrialUser(isTrial);
      setIsPayingUser(isPaying);

      // checking if any of the plan is active if not, redirect to pricing page
      let showPricingPage = !(isTrial || isPaying);

      if (showPricingPage) {
        router.replace(`${Routes.pricing}?showUpgradeMessage=true`, `${Routes.pricing}`, {
          shallow: true,
        });
        return;
      }

      const resp = await api.getMonthlyQuota();
      setMonthlyQuota(resp ? resp : 0);
    } catch (error) {
      if (
        router.asPath.includes('login') ||
        router.asPath.includes('register') ||
        router.asPath.includes('verify') ||
        router.asPath.includes('pricing') ||
        router.asPath.includes('post/')
      ) {
        return;
      }
      router.replace(`/${router.locale}${Routes.login}`, `/${router.locale}${Routes.login}`, {
        shallow: true,
      });
    }
  }, [router, api, stripeApi]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchNotifications = useCallback(async () => {
    setNotificationInfo((notificationInfo) => ({
      ...notificationInfo,
      isLoading: true,
      updateNotificationId: [],
    }));
    const notifications = await api.getNotifications();
    if (notifications?.notifications)
      setNotificationInfo({
        isLoading: false,
        updateNotificationId: [],
        notifications: notifications.notifications,
        showToastMsg: false,
      });
  }, []);

  const updatNotificationStatus = useCallback(async (notification_id: string[], showToastMsg) => {
    try {
      const allResponse: boolean[] = [];
      notification_id.forEach(async (id, index) => {
        const res = await api.updateNotificationStatus({
          notification_id: id,
        });
        allResponse[index] = res.success;
      });
      await fetchNotifications();
      if (showToastMsg) {
        // if any of response if false we will throw err and will go to catch
        if (!allResponse.includes(false))
          toast.success(intl.formatMessage({ id: 'notification.read.api.sucess.msg' }), toastOptions);
        else throw false;
      }
    } catch (e) {
      showToastMsg && toast.error(intl.formatMessage({ id: 'notification.read.api.err.msg' }), toastOptions);
    }
  }, []);

  useEffect(() => {
    if (profileInfo?.id) fetchNotifications();
  }, [api, profileInfo?.id]);

  useEffect(() => {
    if (notificationInfo.updateNotificationId.length) {
      updatNotificationStatus(notificationInfo.updateNotificationId, notificationInfo.showToastMsg);
    }
  }, [
    notificationInfo.updateNotificationId,
    notificationInfo.showToastMsg,
    fetchNotifications,
    updatNotificationStatus,
  ]);

  const state: AppState = {
    isLoading,
    profileInfo,
    workspaceInfo,
    notificationInfo,
    billingInfo,
    isTrialUser,
    isPayingUser,
    updateLoadingState,
    updateProfileInfo,
    updateWorkspaceInfo,
    setNotificationInfo,
    summaryHistory,
    setSummaryHistory,
    userDocumentHistory,
    setUserDocumentHistory,
    // static checking here to avoid checking everywhere, and passing in context so everytime we don't need to import billing and workspace
    isUserHasEnterprisePlan:
      workspaceInfo?.isEnterprise || billingInfo?.subscriptionStatus?.tiers?.[0] === SubscriptionTier.ENTERPRISE,
    chatbotController: { isOpen: chatbotDisplay, doc: chatbotDoc },
    updateChatbotDoc,
    triggerChatbotDisplay,
    monthlyQuota,
  };

  return <AppStateContext.Provider value={state}>{children}</AppStateContext.Provider>;
};

export default AppStateProvider;
